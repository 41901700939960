/*.reply-action {*/
/*    color: #007bff; !* Bootstrap's default link color *!*/
/*    cursor: pointer;*/
/*    font-size: 0.9rem;*/
/*    font-weight: 500;*/
/*    margin-top: 8px; !* Spacing between the comment and reply link *!*/
/*    text-decoration: underline;*/
/*}*/

/*.reply-box {*/
/*    margin-top: 12px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*}*/

/*.reply-input {*/
/*    width: 100%;*/
/*    margin-bottom: 8px;*/
/*}*/

/*.submit-reply-btn {*/
/*    padding: 5px 10px;*/
/*    background-color: #007bff;*/
/*    color: white;*/
/*    border: none;*/
/*    border-radius: 5px;*/
/*    cursor: pointer;*/
/*    font-size: 0.8rem;*/
/*}*/

/*.submit-reply-btn:hover {*/
/*    background-color: #0056b3; !* Slightly darker blue on hover *!*/
/*}*/
.reply-form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.reply-name,
.reply-email {
    font-size: 0.9rem;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.reply-message {
    font-size: 0.9rem;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-height: 100px; /* Adjust height as needed */
}

.submit-reply-btn {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-reply-btn:hover {
    background-color: #0056b3;
}

.reply-link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}
