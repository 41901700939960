/* Include this in your main stylesheet or a CSS module */

@media (max-width: 1279px) {
  .fullscreen-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fullscreen-nav ul {
    list-style: none;
    text-align: center;
    padding: 0;
  }

  .fullscreen-nav li {
    margin: 0.5rem;
  }

  .fullscreen-nav li a {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
  }

  .close-popup {
    margin-top: 2rem;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .mobile-nav-toggle {
    display: block; /* Only show the toggle button on small screens */
    cursor: pointer;
  }

  /* Hiding the traditional navbar on small screens */
  #navbar {
    display: none;
  }
}
