/* File path: src/components/admin/BlogList.css */

.blog-list-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.blog-list-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.blog-list-item h2 {
    margin: 0;
    color: #333;
}

.blog-list-item p {
    margin: 5px 0;
}

.blog-list-item button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.blog-list-item button:hover {
    background-color: #0056b3;
}
