.blog-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px !important;
  margin-bottom: 100px !important;

  input[type="text"] {
    margin: 10px 0;
    padding: 8px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
      border-color: #007bff;
    }

    select {
      margin: 10px 0;
      padding: 8px;
      width: 80%;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .rich-text-editor {
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    // Additional styling specific to your RichTextEditor component
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#007bff, 10%);
    }
  }
}
.my-custom-mt {
  margin-top: 6rem;
}

.drop-down {

}
