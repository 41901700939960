.post-content img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 767px) {
  .post-content img {
    height: auto;
  }

}
//.ad-desktop {
//  display: none;
//  @media (min-width: 768px) {
//    display: block; // Show on desktop
//  }
//}
//
//.ad-mobile {
//  display: block;
//  @media (min-width: 768px) {
//    display: none; // Hide on desktop
//  }
//}

/* AdPlaceholder CSS */
.ad-placeholder {
  text-align: center;
  line-height: 90px; /* Default line-height */
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  /* Default size */
  width: 728px;
  height: 90px;
}

@media (max-width: 768px) {
  .ad-placeholder {
    /* Adjust size for mobile devices */
    width: 300px;
    height: 250px;
    line-height: 250px; /* Adjust line-height to match height */
  }
}
